<template>
  <section class="topfilms list">
    <h2>
      <nuxt-link to="/popular">Самые просматриваемые</nuxt-link>
    </h2>
    <Slider v-if="items.length" :videos="{ items: items }" class="anime" />
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Slider: () => import('~/components/Slider.vue')
  },
  computed: mapState(['isDesktop']),
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const { data } = await this.$axios.get('/api/viewpost')
      if (!data.error) {
        this.items = data
      }
    }
  }
}
</script>

<style lang="stylus">
.topfilms
    height: 410px;
    // margin: 20px 0px
    padding: 20px 0px 20px 10px
    .slider-wrapper
        width: 100%
</style>
